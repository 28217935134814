<template>
  <v-container class="mt-5 ml-2" fluid>
    <v-row>
      <v-col cols="12" md="8" lg="6">
      	
      	<v-card class="shadowCard">
      	  <v-card-title class="text-subtitle-1">
      	    Encuestas de ambiente laboral
      	    <v-spacer></v-spacer>

      	    <!-- Botón par aexportar -->
            <!-- <v-btn 
              class="mr-2" 
              small dark color="green"
              @click="exportar()"
            >
              <v-icon>mdi-microsoft-excel</v-icon>
            </v-btn>
            -->
            
      	  	<!-- Botón para consultar la iformación al sistema -->
      	    <v-btn 
              small dark color="primary"
              @click="consultar()"
            >
              Consultar
            </v-btn>

      	  </v-card-title>

      	  <!-- Tabla y filtros -->
      	  <v-card-text>

            <!-- Filtro de ciclos -->
            <v-row>
              <v-col cols="12" sm="6" lg="4" xl="3">
                <v-autocomplete
                  filled
                  dense
                  clearable
                  v-model="ciclo"
                  :items="ciclos"
                  label="Selecciona ciclo"
                  persistent-hint
                  item-text="ciclo"
                  item-value="id_ciclo"
                >
                </v-autocomplete>
              </v-col>
            </v-row>


            <v-row v-if="jefePuesto">
              <v-col cols="12" class="text-center mb-4">
                <div class="black--text"><b>Jefe directo: </b> {{ jefePuesto.nombre_completo }} </div>
              </v-col>
            </v-row>

      	  	<v-window v-model="step">
				    	<v-window-item v-for="(categoria, i ) in categorias" :key="i" :value="i+1">

				    		<div class="text-center black--text"><b>{{ categoria.categoria }}</b></div>
				        <v-card-text v-for="(pregunta, j) in categoria.preguntas" :key="j">
				        	<div class="text-center"><b>{{ pregunta.pregunta }}</b></div>
						      <ul class="feedback" style="text-align: center; align-content: center; align-items: center; justify-content: center; justify-items: center;" v-if="pregunta.tipo_pregunta == 1">
						        <li :class="`angry ${ pregunta.valor == 1 ? 'active' : '' }`" @click="pregunta.valor = 1">
						          <div>
						            <svg class="eye left">
						              <use xlink:href="#eye"></use>
						            </svg>
						            <svg class="eye right">
						              <use xlink:href="#eye"></use>
						            </svg>
						            <svg class="mouth">
						              <use xlink:href="#mouth"></use>
						            </svg>
						          </div>
						        </li>
						        <li :class="`sad ${ pregunta.valor == 2 ? 'active' : '' }`" @click="pregunta.valor = 2">
						          <div>
						            <svg class="eye left">
						              <use xlink:href="#eye"></use>
						            </svg>
						            <svg class="eye right">
						              <use xlink:href="#eye"></use>
						            </svg>
						            <svg class="mouth">
						              <use xlink:href="#mouth"></use>
						            </svg>
						          </div>
						        </li>

						        <li :class="`ok ${ pregunta.valor == 3 ? 'active' : '' }`" @click="pregunta.valor = 3">
						          <div></div>
						        </li>

						        <li :class="`good ${ pregunta.valor == 4 ? 'active' : '' }`" @click="pregunta.valor = 4">
						          <div>
						            <svg class="eye left">
						              <use xlink:href="#eye"></use>
						            </svg>
						            <svg class="eye right">
						              <use xlink:href="#eye"></use>
						            </svg>
						            <svg class="mouth">
						              <use xlink:href="#mouth"></use>
						            </svg>
						          </div>
						        </li>
						        <li :class="`happy ${ pregunta.valor == 5 ? 'active' : '' }`" @click="pregunta.valor = 5">
						          <div>
						            <svg class="eye left">
						              <use xlink:href="#eye"></use>
						            </svg>
						            <svg class="eye right">
						              <use xlink:href="#eye"></use>
						            </svg>
						          </div>
						        </li>
						      </ul>

                  <v-textarea
                    v-model="pregunta.texto"
                    v-else
                    filled
                    dense
                    :rows="3"
                    auto-grow
                  >
                  </v-textarea>
						      
						      <svg xmlns="http://www.w3.org/2000/svg" style="display: none;">
						        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 7 4" id="eye">
						          <path d="M1,1 C1.83333333,2.16666667 2.66666667,2.75 3.5,2.75 C4.33333333,2.75 5.16666667,2.16666667 6,1"></path>
						        </symbol>
						        <symbol xmlns="http://www.w3.org/2000/svg" viewBox="0 0 18 7" id="mouth">
						          <path d="M1,5.5 C3.66666667,2.5 6.33333333,1 9,1 C11.6666667,1 14.3333333,2.5 17,5.5"></path>
						        </symbol>
						      </svg>
				        </v-card-text>
				      </v-window-item>

				    </v-window>

		        <!-- Barra superior -->
				  	<v-row>
				  		<v-col cols="12">
					  		
				  		</v-col>
				  	</v-row>
      	  </v-card-text>
      	  <v-card-actions>
			      <v-btn
			      	v-if="step > 1"
			        :disabled="step === 1"
			        text
			        @click="step--"
			        dense
			        rounded
			      >
			        Regresar
			      </v-btn>
			      <v-spacer></v-spacer>
			      <v-btn
			        v-if="step < categorias.length "
			        color="primary"
			        depressed
			        @click="avanzarStep()"
			        dense
			        rounded
			      >
			        Siguiente
			      </v-btn>

			      <v-btn
			        v-if="step == categorias.length  "
			        color="primary"
			        depressed
			        @click="save()"
			        dense
			        rounded
			      >
			        Enviar
			      </v-btn>
			    </v-card-actions>
      	</v-card>

      </v-col>
    </v-row>

    <!-- Dialog de carga -->
    <Alerta               v-if="respuestaAlerta"             :parametros="parametros"/>
    <carga                v-if="cargar"/>

	</v-container>
</template>
<script>
import axios from 'axios';
	import { mapGetters } from 'vuex'
	// Componentes
  import Alerta                from '@/components/alertas/Alerta.vue'
  import carga                 from '@/components/alertas/carga.vue';

  import VueApexCharts         from 'vue-apexcharts'

  import validarErrores        from '@/mixins/validarErrores'
  import funcionesExcel        from '@/mixins/funcionesExcel'


  export default {

  	components:{
      Alerta,
      carga,
      VueApexCharts
    },

    mixins: [ validarErrores, funcionesExcel ],

    data: () => ({
    	// Alertas
    	parametros:{
        dialogError: false,
        mensaje: '',
        color: ''
      },

      respuestaAlerta:false,
      loader: true,
      cargar: false,

      ciclos:[],
      ciclo:null,

      dialogReemplazo: false,

      // Filtros avanzados

      teachersFiltros:[],
			teacherFilter: null,

			dialogMaestro: false,

			escuela: 1,

			categorias:[],
	  	step: 1,

      jefePuesto: null,
      
    }),

    computed: {
    	...mapGetters("login", ["getdatosUsuario", "getLogeado"]),
  	},

    watch: {
      // Consultar cuando haya un cambio en el ciclo y este sea un ciclo seleccionado y no un ciclo vacio
      ciclo () {
        if(this.ciclo){
          this.consultar()
        }
      }
    },

    async created () {
      console.log( this.getdatosUsuario )
      await this.initialize()
    },

    methods: {
      // Funcion para cargar datos iniciales
      initialize () {
      	this.cargar = true
        // Vaciar los ciclos
        this.ciclos = []
        // Obtener todos los ciclos
        this.$http.get('roles.ciclos.all').then(response=>{
          // hacer un ciclo ya que los ciclos vienen varios como inscripciones y todo eso
          // y solo queremos los ciclos que en su nombre dicen ciclo 
          for(const i in response.data){
            //  si es diferente a FE
            if (!(response.data[i].ciclo.search('FE') != -1)) {
              // Y es igual a FE, se agrega a ciclos
              // por qué es diferente a FE? por que es por estetica, mostrar solo los ciclos normales, ya que los ciclos tienen ligado el ciclo FE
              if(response.data[i].ciclo.search('CICLO') != -1){
                this.ciclos.push(response.data[i])
              }
            }
          }

      		this.cargar = false

        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },


      consultar () {

        if( !this.ciclo  ){
          return this.validarErrorDirecto('Favor de seleccionar un cuclo')
        }


      	const payload = {
      		id_usuario  : this.getdatosUsuario.iderp,
          id_ciclo    : this.ciclo,
          id_puesto   : this.getdatosUsuario.idpuesto,
          id_plantel  : this.getdatosUsuario.id_plantel
      	}
      	
      	this.categorias = []

      	this.cargar = true
        this.$http.post('encuestas.ambiente', payload ).then(response=>{
          this.categorias         = response.data.encuesta
          this.jefePuesto         = response.data.jefePuesto
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

      exportarPreguntas(){
      	this.exportExcel( this.data  , 'preguntas_abiertas')
      },

      exportar( ){
      	this.exportExcel( this.filterGrupos  , 'ROL_CLASES')
      },

      avanzarStep( ){
    		this.step += 1
      },

      
      save () {

        if( !this.ciclo  ){
          return this.validarErrorDirecto('Favor de seleccionar un cuclo')
        }


        const payload = {
          id_usuario  : this.getdatosUsuario.iderp,
          id_ciclo    : this.ciclo,
          categorias  : this.categorias,
          id_jefe     : this.jefePuesto.iderp
        }
        
        this.cargar = true
        this.$http.post('encuestas.ambiente.add', payload ).then(response=>{
          this.consultar()
          this.categorias         = []
          this.jefePuesto         = null
          this.cargar      = false
        }).catch( error =>{
          this.validarError( error.response.data.message )
        }).finally( () => { this.cargar = false })
      },

    },
  }

</script>
<style>
  .v-data-table__wrapper{

  }

  .v-data-table__wrapper::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-data-table__wrapper::-webkit-scrollbar:vertical {
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar:vertical{
    width: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-button:increment,.v-data-table__wrapper::-webkit-scrollbar-button{
    display: none;
  }

  .v-data-table__wrapper::-webkit-scrollbar:horizontal{
    height: 10px !important;
  }

  .v-data-table__wrapper::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

	.text-gradient{
		color: #82d616!important;
	}

  .text-typo {
    color: #344767!important;
	}

	.check {
	  position: absolute;
	  top: -10px;
	  right: 90px;
	  width: 30px;
	}


	.v-btn--example {
    bottom: 0;
    position: absolute;
    margin: 0 0 16px 16px;
    right: 10px;
  }
  /* Demo Stuff */
  a.more-link {
    background-color: rgba(255, 255, 255, 0.35);
    display: inline-block;
    padding: 12px 18px;
    color: #fff;
    text-decoration: none;
    font: 500 15px 'Work Sans', Helvetica, sans-serif;
    line-height: 1.5;
    text-align: center;
    border: none !important;
    position: relative;
    border-radius: 30px;
    text-transform: uppercase;
    -webkit-transition: .1s all ease-in-out;
    -moz-transition: .1s all ease-in-out;
    -o-transition: .1s all ease-in-out;
    transition: .1s all ease-in-out;
  }
  a.more-link:hover {
    background-color: rgba(255, 255, 255, 0.50);
  }
  
  .link-container {
    text-align: center;
  }
  .link-container a.more-link {
    font-family: 'Source Sans Pro', Helvetica, sans-serif;
    background-color: #90b0bf;
    color: #fff;
    display: inline-block;
    margin-right: 5px;
    margin-bottom: 5px;
    line-height: 1.5;
    text-decoration: none;
    text-transform: none;
    font-weight: 400;
    letter-spacing: 1px;
  }


  .v-dialog__content.v-dialog__content--active::-webkit-scrollbar {
    -webkit-appearance: none !important;
  }

  .v-data-table--fixed-header > .v-dialog__content.v-dialog__content--active::-webkit-scrollbar:vertical {
    width: 8px !important;
  }

  .v-dialog__content.v-dialog__content--active::-webkit-scrollbar:vertical{
    width: 8px !important;
  }

  .v-dialog__content.v-dialog__content--active::-webkit-scrollbar-button:increment,.v-dialog__content.v-dialog__content--active::-webkit-scrollbar-button{
    display: none;
  }

  .v-dialog__content.v-dialog__content--active::-webkit-scrollbar:horizontal{
    height: 8px !important;
  }

  .v-dialog__content.v-dialog__content--active::-webkit-scrollbar-thumb{
    background-color: #BABCBF;
    border-radius: 20px;
    border: 1px solid #ededed;
  }

  .feedback {
    --normal: #ECEAF3;
    --normal-shadow: #D9D8E3;
    --normal-mouth: #9795A4;
    --normal-eye: #595861;
    --active: #F8DA69;
    --active-shadow: #F4B555;
    --active-mouth: #F05136;
    --active-eye: #313036;
    --active-tear: #76b5e7;
    --active-shadow-angry: #e94f1d;
    margin: 0;
    padding: 0;
    list-style: none;
    display: flex;
  }
  .feedback li {
    position: relative;
    border-radius: 50%;
    background: var(--sb, var(--normal));
    box-shadow: inset 3px -3px 4px var(--sh, var(--normal-shadow));
    transition: background 0.4s, box-shadow 0.4s, transform 0.3s;
    -webkit-tap-highlight-color: transparent;
  }
  .feedback li:not(:last-child) {
    margin-right: 20px;
  }
  .feedback li div {
    width: 40px;
    height: 40px;
    position: relative;
    transform: perspective(240px) translateZ(4px);
  }
  .feedback li div svg, .feedback li div:before, .feedback li div:after {
    display: block;
    position: absolute;
    left: var(--l, 9px);
    top: var(--t, 13px);
    width: var(--w, 8px);
    height: var(--h, 2px);
    transform: rotate(var(--r, 0deg)) scale(var(--sc, 1)) translateZ(0);
  }
  .feedback li div svg {
    fill: none;
    stroke: var(--s);
    stroke-width: 2px;
    stroke-linecap: round;
    stroke-linejoin: round;
    transition: stroke 0.4s;
  }
  .feedback li div svg.eye {
    --s: var(--e, var(--normal-eye));
    --t: 17px;
    --w: 7px;
    --h: 4px;
  }
  .feedback li div svg.eye.right {
    --l: 23px;
  }
  .feedback li div svg.mouth {
    --s: var(--m, var(--normal-mouth));
    --l: 11px;
    --t: 23px;
    --w: 18px;
    --h: 7px;
  }
  .feedback li div:before, .feedback li div:after {
    content: "";
    z-index: var(--zi, 1);
    border-radius: var(--br, 1px);
    background: var(--b, var(--e, var(--normal-eye)));
    transition: background 0.4s;
  }
  .feedback li.angry {
    --step-1-rx: -24deg;
    --step-1-ry: 20deg;
    --step-2-rx: -24deg;
    --step-2-ry: -20deg;
  }
  .feedback li.angry div:before {
    --r: 20deg;
  }
  .feedback li.angry div:after {
    --l: 23px;
    --r: -20deg;
  }
  .feedback li.angry div svg.eye {
    stroke-dasharray: 4.55;
    stroke-dashoffset: 8.15;
  }
  .feedback li.angry.active {
    -webkit-animation: angry 1s linear;
            animation: angry 1s linear;
  }
  .feedback li.angry.active div:before {
    --middle-y: -2px;
    --middle-r: 22deg;
    -webkit-animation: toggle 0.8s linear forwards;
            animation: toggle 0.8s linear forwards;
  }
  .feedback li.angry.active div:after {
    --middle-y: 1px;
    --middle-r: -18deg;
    -webkit-animation: toggle 0.8s linear forwards;
            animation: toggle 0.8s linear forwards;
  }
  .feedback li.sad {
    --step-1-rx: 20deg;
    --step-1-ry: -12deg;
    --step-2-rx: -18deg;
    --step-2-ry: 14deg;
  }
  .feedback li.sad div:before, .feedback li.sad div:after {
    --b: var(--active-tear);
    --sc: 0;
    --w: 5px;
    --h: 5px;
    --t: 15px;
    --br: 50%;
  }
  .feedback li.sad div:after {
    --l: 25px;
  }
  .feedback li.sad div svg.eye {
    --t: 16px;
  }
  .feedback li.sad div svg.mouth {
    --t: 24px;
    stroke-dasharray: 9.5;
    stroke-dashoffset: 33.25;
  }
  .feedback li.sad.active div:before, .feedback li.sad.active div:after {
    -webkit-animation: tear 0.6s linear forwards;
            animation: tear 0.6s linear forwards;
  }
  .feedback li.ok {
    --step-1-rx: 4deg;
    --step-1-ry: -22deg;
    --step-1-rz: 6deg;
    --step-2-rx: 4deg;
    --step-2-ry: 22deg;
    --step-2-rz: -6deg;
  }
  .feedback li.ok div:before {
    --l: 12px;
    --t: 17px;
    --h: 4px;
    --w: 4px;
    --br: 50%;
    box-shadow: 12px 0 0 var(--e, var(--normal-eye));
  }
  .feedback li.ok div:after {
    --l: 13px;
    --t: 26px;
    --w: 14px;
    --h: 2px;
    --br: 1px;
    --b: var(--m, var(--normal-mouth));
  }
  .feedback li.ok.active div:before {
    --middle-s-y: .35;
    -webkit-animation: toggle 0.2s linear forwards;
            animation: toggle 0.2s linear forwards;
  }
  .feedback li.ok.active div:after {
    --middle-s-x: .5;
    -webkit-animation: toggle 0.7s linear forwards;
            animation: toggle 0.7s linear forwards;
  }
  .feedback li.good {
    --step-1-rx: -14deg;
    --step-1-rz: 10deg;
    --step-2-rx: 10deg;
    --step-2-rz: -8deg;
  }
  .feedback li.good div:before {
    --b: var(--m, var(--normal-mouth));
    --w: 5px;
    --h: 5px;
    --br: 50%;
    --t: 22px;
    --zi: 0;
    opacity: 0.5;
    box-shadow: 16px 0 0 var(--b);
    filter: blur(2px);
  }
  .feedback li.good div:after {
    --sc: 0;
  }
  .feedback li.good div svg.eye {
    --t: 15px;
    --sc: -1;
    stroke-dasharray: 4.55;
    stroke-dashoffset: 8.15;
  }
  .feedback li.good div svg.mouth {
    --t: 22px;
    --sc: -1;
    stroke-dasharray: 13.3;
    stroke-dashoffset: 23.75;
  }
  .feedback li.good.active div svg.mouth {
    --middle-y: 1px;
    --middle-s: -1;
    -webkit-animation: toggle 0.8s linear forwards;
            animation: toggle 0.8s linear forwards;
  }
  .feedback li.happy div {
    --step-1-rx: 18deg;
    --step-1-ry: 24deg;
    --step-2-rx: 18deg;
    --step-2-ry: -24deg;
  }
  .feedback li.happy div:before {
    --sc: 0;
  }
  .feedback li.happy div:after {
    --b: var(--m, var(--normal-mouth));
    --l: 11px;
    --t: 23px;
    --w: 18px;
    --h: 8px;
    --br: 0 0 8px 8px;
  }
  .feedback li.happy div svg.eye {
    --t: 14px;
    --sc: -1;
  }
  .feedback li.happy.active div:after {
    --middle-s-x: .95;
    --middle-s-y: .75;
    -webkit-animation: toggle 0.8s linear forwards;
            animation: toggle 0.8s linear forwards;
  }

  .feedback li:not(.active) {
    cursor: pointer;
  }

  .feedback li:not(.active):active {
    transform: scale(0.925);
  }
  .feedback li.active {
    --sb: var(--active);
    --sh: var(--active-shadow);
    --m: var(--active-mouth);
    --e: var(--active-eye);
  }
  .feedback li.active div {
    -webkit-animation: shake 0.8s linear forwards;
            animation: shake 0.8s linear forwards;
  }

  @keyframes shake {
    30% {
      transform: perspective(240px) rotateX(var(--step-1-rx, 0deg)) rotateY(var(--step-1-ry, 0deg)) rotateZ(var(--step-1-rz, 0deg)) translateZ(10px);
    }
    60% {
      transform: perspective(240px) rotateX(var(--step-2-rx, 0deg)) rotateY(var(--step-2-ry, 0deg)) rotateZ(var(--step-2-rz, 0deg)) translateZ(10px);
    }
    100% {
      transform: perspective(240px) translateZ(4px);
    }
  }
  @-webkit-keyframes tear {
    0% {
      opacity: 0;
      transform: translateY(-2px) scale(0) translateZ(0);
    }
    50% {
      transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
    }
    20%, 80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
    }
  }
  @keyframes tear {
    0% {
      opacity: 0;
      transform: translateY(-2px) scale(0) translateZ(0);
    }
    50% {
      transform: translateY(12px) scale(0.6, 1.2) translateZ(0);
    }
    20%, 80% {
      opacity: 1;
    }
    100% {
      opacity: 0;
      transform: translateY(24px) translateX(4px) rotateZ(-30deg) scale(0.7, 1.1) translateZ(0);
    }
  }
  @-webkit-keyframes toggle {
    50% {
      transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
    }
  }
  @keyframes toggle {
    50% {
      transform: translateY(var(--middle-y, 0)) scale(var(--middle-s-x, var(--middle-s, 1)), var(--middle-s-y, var(--middle-s, 1))) rotate(var(--middle-r, 0deg));
    }
  }
  @-webkit-keyframes angry {
    40% {
      background: var(--active);
    }
    45% {
      box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
    }
  }
  @keyframes angry {
    40% {
      background: var(--active);
    }
    45% {
      box-shadow: inset 3px -3px 4px var(--active-shadow), inset 0 8px 10px var(--active-shadow-angry);
    }
  }

  html {
    box-sizing: border-box;
    -webkit-font-smoothing: antialiased;
  }

  * {
    box-sizing: inherit;
  }
  *:before, *:after {
    box-sizing: inherit;
  }

  #modalCokie {
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-x: hidden;
    display: block;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 250000ms 700000ms;
    overflow-x: hidden;
    background-color: rgba(31,32,41,.75);
    align-items: center;
  }

  .encuesta2 {
    z-index: 1;
    position: fixed;
    top: 0px;
    left: 0px;
    overflow-x: hidden;
    display: block;
    display: -ms-flexbox;
    display: flex;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
    -ms-flex-pack: center;
    justify-content: center;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: opacity 250000ms 700000ms;
    overflow-x: hidden;
    background-color: rgba(31,32,41,.75);
    align-items: center;
  }

  .cookiesContent {
    justify-content: center;
    align-items: center;
    vertical-align: middle;
    max-width: 600px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 20px;
    padding: 30px 30px 70px;
  }

  .cookiesContent button.close {
    width: 30px;
    font-size: 20px;
    color: #c0c5cb;
    align-self: flex-end;
    background-color: transparent;
    border: none;
    margin-bottom: 10px;
  }

  .cookiesContent img {
    width: 250px;
    margin-bottom: 15px;
  }

  .cookiesContent p {
    margin-bottom: 40px;
    font-size: 18px;
  }

  .cookiesContent button.accept {
    background-color: #ed6755;
    border: none;
    border-radius: 5px;
    width: 200px;
    padding: 14px;
    font-size: 16px;
    color: white;
    box-shadow: 0px 6px 18px -5px #ed6755;
  }

  @media only screen and (max-width: 1026px) {
      #fadeshow1 {
          display: none;
      }
  }

  #page-top {
    padding-right: 0px;
  }

  .progress {
    width: 150px;
    height: 150px;
    background: none;
    position: relative;
  }

  .progress::after {
    content: "";
    width: 100%;
    height: 100%;
    border-radius: 50%;
    border: 22px solid #eee;
    position: absolute;
    top: 0;
    left: 0;
  }

  .progress>span {
    width: 50%;
    height: 100%;
    overflow: hidden;
    position: absolute;
    top: 0;
    z-index: 1;
  }

  .progress .progress-left {
    left: 0;
  }

  .progress .progress-bar {
    width: 100%;
    height: 100%;
    background: none;
    border-width: 22px;
    border-style: solid;
    position: absolute;
    top: 0;
  }

  .progress .progress-left .progress-bar {
    left: 100%;
    border-top-right-radius: 80px;
    border-bottom-right-radius: 80px;
    border-left: 0;
    -webkit-transform-origin: center left;
    transform-origin: center left;
  }

  .progress .progress-right {
    right: 0;
  }

  .progress .progress-right .progress-bar {
    left: -100%;
    border-top-left-radius: 80px;
    border-bottom-left-radius: 80px;
    border-right: 0;
    -webkit-transform-origin: center right;
    transform-origin: center right;
  }

  .progress .progress-value {
    position: absolute;
    top: 0;
    left: 0;
  }

  /*******************************************************************/
  #card-grupo {
    background: rgb(0, 0, 99);
    background: linear-gradient(90deg, rgba(0, 0, 99, 0.8519782913165266) 9%, rgba(9, 75, 121, 0.8407738095238095) 44%, rgba(185, 242, 255, 1) 100%);
    color: #FFF !important;
  }

  /******************************************************************/
  #chartdiv {
    width: 100%;
    height: 130px;
  }

  /*
  *
  * ==========================================
  * FOR DEMO PURPOSE
  * ==========================================
  *
  */

  body {
    background: #ff7e5f;
    background: -webkit-linear-gradient(to right, #ff7e5f, #feb47b);
    background: linear-gradient(to right, #ff7e5f, #feb47b);
    min-height: 100vh;
    padding-right: 0px !important;
  }

  .rounded-lg {
    border-radius: 1rem;
  }

  .text-gray {
    color: #aaa;
  }

  div.h4 {
    line-height: 1rem;
  }

  .scroll {
    overflow-y: hidden;
  }
</style>